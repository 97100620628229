
.modal,
.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;

}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  
 display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:#b4c066;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  border-radius: 15px;
  
}


button{
  margin-left: auto;
  margin-right: auto
}

/* 
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */